import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgProgressRef, NgProgress } from 'ngx-progressbar';
import { Subject } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core';
import { MainService } from './services/main.service';
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private progressRef: NgProgressRef;
  private onDestroy: Subject<void> = new Subject<void>();
  readonly storageLanguageKey = 'language';

  constructor(
    private mainServ: MainService,
    private translateServ: TranslateService,
    public router: Router,
    private acRoute: ActivatedRoute,
    private titleServ: Title,
    private ngProgress: NgProgress,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.progressRef = this.ngProgress.ref('progress');
  }

  ngOnInit(): void {
    this.createYoutubeAPITag();
    this.setAppLanguage();
    this.setLanguageObserver();
  }

  setLanguageObserver() {
    this.mainServ.setLanguage.pipe(
      takeUntil(this.onDestroy)
    ).subscribe((language: string) => this.setLanguage(language));
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  setAppLanguage(): void {
    const navLanguage: string = navigator.language;
    let language: string;

    language = navLanguage.includes(Elanguages.SPANISH) ? Elanguages.SPANISH : Elanguages.ENGLISH;

    const storedLanguage: string | null = localStorage.getItem(this.storageLanguageKey);
    if (storedLanguage && storedLanguage != '') {
      language = storedLanguage;
    }

    this.translateServ.setDefaultLang(language);

    this.setRouterEvents();
  }

  setLanguage(language: string): void {
    localStorage.setItem(this.storageLanguageKey, language);
    window.location.reload();
  }

  setRouterEvents() {
    this.router.events.pipe(
      takeUntil(this.onDestroy),
      filter(event => event instanceof NavigationStart)
    ).subscribe((e: any) => {
      this.progressRef.start();
    });

    this.router.events.pipe(
      takeUntil(this.onDestroy),
      filter(event => event instanceof NavigationEnd),
      map((event: any) => {
        gtag('config', 'UA-37153530-2', {
          'pagina': (event as NavigationEnd).urlAfterRedirects
        });

        let child = this.acRoute.firstChild;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['pageTitle']) {
            return child.snapshot.data['pageTitle'];
          } else {
            return null;
          }
        }

        return null;
      })
    ).subscribe((pageTitle: string) => {
      pageTitle = this.translateServ.instant(pageTitle);
      this.titleServ.setTitle(pageTitle);

      this.progressRef.complete();
    });
  }

  createYoutubeAPITag() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

}

enum Elanguages {
  SPANISH = 'es',
  ENGLISH = 'en'
}