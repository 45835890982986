<header>
    <nav [ngClass]="{'ttt-nav': true, 'ttt-nav-light': lightNavbar || router.url !== '/'}">
        <div class="nav-row">
            <div class="logo">
                <a routerLink="/">
                    <div class="mk-logo">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 119">
                            <path class="cls-ttt-1"
                                d="M72.81,62.32v35.2h0a7.84,7.84,0,0,0,7.83-7.84V62.32A13.59,13.59,0,0,1,94.23,48.73h6.88a7.84,7.84,0,0,0,7.83-7.83H94.23A21.42,21.42,0,0,0,72.81,62.32Z" />
                            <path class="cls-ttt-1"
                                d="M60.19,60.35V99.41a3.92,3.92,0,0,0,3.91,3.92h0A3.92,3.92,0,0,0,68,99.41V60.35A24.23,24.23,0,0,1,92.25,36.12h15.9A7.83,7.83,0,0,0,116,28.29H92.25A32.06,32.06,0,0,0,60.19,60.35Z" />
                            <path class="cls-ttt-1"
                                d="M89.53,15.67A41.77,41.77,0,0,0,64,24.36a41.85,41.85,0,0,0-25.53-8.69H4.64a7.84,7.84,0,0,0,7.83,7.84h26a34,34,0,0,1,19.7,6.29,41.39,41.39,0,0,0-3.58,4.63,31.66,31.66,0,0,0-18.84-6.14h-24a7.84,7.84,0,0,0,7.83,7.83H35.75a23.94,23.94,0,0,1,15.08,5.29,41.65,41.65,0,0,0-1.94,5.74A21.38,21.38,0,0,0,33.77,40.9H19.1a7.84,7.84,0,0,0,7.83,7.83H33.3a14.06,14.06,0,0,1,12.27,6.83,13.64,13.64,0,0,1,1.79,6.76V89.68a7.84,7.84,0,0,0,7.83,7.84h.21V57.63A34.13,34.13,0,0,1,89.53,23.51h26a7.84,7.84,0,0,0,7.83-7.84H89.53Z" />
                            <path class="cls-ttt-2"
                                d="M38.47,15.68H4.64a7.84,7.84,0,0,0,7.83,7.83h26A34.06,34.06,0,0,1,57.9,29.62h0l.29.18A32.37,32.37,0,0,1,64,24.36,41.84,41.84,0,0,0,38.47,15.68Z" />
                            <path class="cls-ttt-2"
                                d="M48.89,47.15l-.29-.26h0a21.35,21.35,0,0,0-14.83-6H19.1a7.84,7.84,0,0,0,7.83,7.83H33.3a14.06,14.06,0,0,1,12.27,6.83,13.64,13.64,0,0,1,1.79,6.76A51.51,51.51,0,0,1,48.89,47.15Z" />
                            <path class="cls-ttt-2"
                                d="M35.75,36.12a24,24,0,0,1,14.84,5.12h0l.07.05a1.92,1.92,0,0,0,.17.13h0s2.45-5,3.76-7l-.17-.12a31.68,31.68,0,0,0-18.67-6h-24a7.84,7.84,0,0,0,7.83,7.83Z" />
                        </svg>

                        <svg [ngClass]="{'ttt-light': true, 'ttt-dark': lightNavbar || router.url !== '/'}" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 247 81">
                            <path
                                d="M14.81,60.87H18.1c2,0,3.28,1.05,3.28,2.79v0c0,1.89-1.58,2.87-3.45,2.87H15.8v3.1h-1ZM18,65.65c1.46,0,2.41-.78,2.41-1.93v0c0-1.25-.94-1.9-2.36-1.9H15.8v3.86Z" />
                            <path
                                d="M25.07,66v-5.1h1v5c0,1.9,1,3,2.68,3s2.65-1,2.65-2.9v-5.1h1v5c0,2.56-1.47,3.89-3.66,3.89S25.07,68.46,25.07,66Z" />
                            <path d="M36.74,60.87h6.35v.9H37.73v3h4.79v.9H37.73v3.08h5.42v.9H36.74Z" />
                            <path
                                d="M47.16,60.87h3.78a3.45,3.45,0,0,1,2.49.88,2.42,2.42,0,0,1,.68,1.73v0a2.56,2.56,0,0,1-2.38,2.56l2.69,3.59H53.21l-2.55-3.41H48.15v3.41h-1Zm3.69,4.48c1.32,0,2.26-.68,2.26-1.81v0c0-1.08-.83-1.73-2.25-1.73H48.15v3.56Z" />
                            <path d="M60.48,61.79H57.53v-.92h6.9v.92h-3v7.86h-1Z" />
                            <path
                                d="M67.31,65.28v0a4.48,4.48,0,1,1,9,0v0a4.48,4.48,0,1,1-9,0Zm7.93,0v0a3.49,3.49,0,0,0-3.46-3.62,3.45,3.45,0,0,0-3.44,3.6v0a3.49,3.49,0,0,0,3.46,3.62A3.45,3.45,0,0,0,75.24,65.28Z" />
                            <path d="M88.48,61.79h-3v-.92h6.9v.92h-3v7.86h-1Z" />
                            <path
                                d="M95.31,65.28v0a4.45,4.45,0,0,1,4.49-4.54,4.4,4.4,0,0,1,4.46,4.52v0a4.48,4.48,0,1,1-9,0Zm7.93,0v0a3.49,3.49,0,0,0-3.47-3.62,3.44,3.44,0,0,0-3.43,3.6v0a3.49,3.49,0,0,0,3.46,3.62A3.45,3.45,0,0,0,103.24,65.28Z" />
                            <path
                                d="M108.38,60.87h3.28c2,0,3.29,1.05,3.29,2.79v0c0,1.89-1.58,2.87-3.45,2.87h-2.13v3.1h-1Zm3.16,4.78c1.45,0,2.41-.78,2.41-1.93v0c0-1.25-.94-1.9-2.36-1.9h-2.22v3.86Z" />
                            <path
                                d="M118.39,65.28v0a4.48,4.48,0,1,1,9,0v0a4.45,4.45,0,0,1-4.49,4.54A4.4,4.4,0,0,1,118.39,65.28Zm7.92,0v0a3.49,3.49,0,0,0-3.46-3.62,3.45,3.45,0,0,0-3.44,3.6v0a3.49,3.49,0,0,0,3.47,3.62A3.44,3.44,0,0,0,126.31,65.28Z" />
                            <path d="M131.45,60.87h1v7.86h4.95v.92h-5.94Z" />
                            <path
                                d="M140.34,65.28v0a4.48,4.48,0,1,1,9,0v0a4.48,4.48,0,1,1-9,0Zm7.93,0v0a3.49,3.49,0,0,0-3.46-3.62,3.45,3.45,0,0,0-3.44,3.6v0a3.49,3.49,0,0,0,3.46,3.62A3.45,3.45,0,0,0,148.27,65.28Z" />
                            <path
                                d="M153.41,60.87h3.74a3.21,3.21,0,0,1,2.29.78,1.93,1.93,0,0,1,.57,1.4v0a2.12,2.12,0,0,1-1.39,2,2.21,2.21,0,0,1,1.88,2.12v0c0,1.51-1.27,2.4-3.19,2.4h-3.9ZM157,64.78c1.19,0,2-.54,2-1.56v0c0-.86-.69-1.43-1.95-1.43h-2.67v3Zm.35,4c1.33,0,2.15-.59,2.15-1.57v0c0-1-.8-1.51-2.33-1.51h-2.77v3.1Z" />
                            <path
                                d="M167.73,60.81h.93l4,8.84H171.6l-1-2.32h-4.78l-1,2.32h-1Zm2.45,5.63-2-4.48-2,4.48Z" />
                            <path
                                d="M176.41,60.87h1l3.19,4.79,3.2-4.79h1v8.78h-1V62.54l-3.19,4.7h0l-3.2-4.69v7.1h-1Z" />
                            <path
                                d="M189.32,60.87h3.28c2,0,3.29,1.05,3.29,2.79v0c0,1.89-1.58,2.87-3.45,2.87h-2.13v3.1h-1Zm3.16,4.78c1.45,0,2.41-.78,2.41-1.93v0c0-1.25-.94-1.9-2.36-1.9h-2.22v3.86Z" />
                            <path
                                d="M199.32,65.28v0a4.48,4.48,0,1,1,9,0v0a4.48,4.48,0,1,1-9,0Zm7.93,0v0a3.49,3.49,0,0,0-3.46-3.62,3.45,3.45,0,0,0-3.44,3.6v0a3.49,3.49,0,0,0,3.46,3.62A3.45,3.45,0,0,0,207.25,65.28Z" />
                            <path
                                d="M240.92,45.35h-.81L239,43.61h-.55v1.76h-.7V40.84h1.47a1.33,1.33,0,0,1,1.49,1.33,1.29,1.29,0,0,1-.93,1.39ZM240,42.28v-.11a.62.62,0,0,0-.55-.63h-1v1.34h1.06A.66.66,0,0,0,240,42.28Z" />
                            <path
                                d="M239.17,47A3.86,3.86,0,1,1,243,43.1,3.86,3.86,0,0,1,239.17,47Zm0-7.1a3.25,3.25,0,1,0,3.24,3.24A3.24,3.24,0,0,0,239.17,39.86Z" />
                            <path d="M4,13.17a6.79,6.79,0,0,0,6.8,6.8h4V47.31h10V20h4a6.79,6.79,0,0,0,6.8-6.8v-2H4Z" />
                            <path
                                d="M203.07,12.38A7.59,7.59,0,0,0,210.66,20h3.24V47.31h10V20h3.24a7.59,7.59,0,0,0,7.59-7.59V11.2H203.07Z" />
                            <rect x="75.25" y="11.2" width="10.06" height="36.11" />
                            <path
                                d="M106.35,11.2H90.88V47.31h10V37h5c9,0,15.53-4.49,15.53-13v-.1C121.37,15.89,115.48,11.2,106.35,11.2Zm5,13.26c0,2.83-2.17,4.69-5.73,4.69h-4.74V19.61h4.69c3.61,0,5.78,1.65,5.78,4.74Z" />
                            <polygon
                                points="134.57 11.2 124.56 11.2 124.56 47.31 152.05 47.31 152.05 38.54 134.57 38.54 134.57 11.2" />
                            <path
                                d="M176.87,38.8H165.21V33.07h13.38a3.95,3.95,0,1,0,0-7.89H165.21V19.71h11.4A7.73,7.73,0,0,0,184.34,12V11.2h-29V47.31h29.3v-.78A7.73,7.73,0,0,0,176.87,38.8Z" />
                            <path
                                d="M71,23.63v-.1a11.17,11.17,0,0,0-3.2-8.41c-2.42-2.48-6.24-3.92-11.76-3.92H39V47.31h2a8,8,0,0,0,8-8.05V19.87h6.55c3.35,0,5.41,1.44,5.41,4.28v.1c0,2.63-2,4.28-5.36,4.28H54.22a2.49,2.49,0,0,0-2.06,3.89l7.53,11.09a8.67,8.67,0,0,0,7.17,3.8h5.35L63.6,34.72A11.42,11.42,0,0,0,71,23.63Z" />
                        </svg>
                    </div>
                </a>
            </div>

            <div class="menu" *ngIf="mainServ.desktopBP">
                <a [ngClass]="{'menu-link': true, 'bien-darks': lightNavbar || router.url !== '/'}" mat-button
                    *ngFor="let item of 'nav' | translate" [routerLink]="item.url" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:true}">{{item.label}}</a>

                <div class="extra">
                    <a  href="http://189.195.162.26:82" target="_blank" mat-flat-button color="primary">{{'common.onlineServs' | translate}}</a>

                    <div [ngClass]="{'language': true, 'language-dark': lightNavbar || router.url !== '/'}">
                        <div (click)="setLanguage('es')">
                            <img src="assets/icons/es.png" alt="Triple T"> <span>ES</span>
                        </div>
                        <div (click)="setLanguage('en')">
                            <img src="assets/icons/en.png" alt="Triple T"> <span>EN</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="menu-button" *ngIf="!mainServ.desktopBP">
                <button mat-icon-button (click)="openMobileMenu()">
                    <mat-icon [ngClass]="{'menu-btn-light': true, 'menu-btn-dark': lightNavbar || router.url !== '/'}">menu</mat-icon>
                </button>
            </div>
        </div>
    </nav>

    <nav [ngClass]="{'nav-mobile': true, 'nav-mobile-open': isNavbarOpen && !mainServ.desktopBP}">
        <div class="nav-toolbar-mobile">
            <img src="assets/logos/Logo-Triple-T.png" alt="Triple T">

            <span class="nav-spacer"></span>

            <button mat-icon-button (click)="closeMobileMenu()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="nav-content-mobile">
            <ul>
                <li *ngFor="let item of 'nav' | translate">
                    <a [routerLink]="item.url" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                        (click)="closeMobileMenu()">
                        {{item.label}}
                    </a>
                </li>
            </ul>
        </div>

        <div class="nav-footer-mobile">
            <div class="language-mobile">
                <img (click)="setLanguage('es')" style="margin-right: 20px;" src="assets/icons/es.png" alt="Triple T">
                <img (click)="setLanguage('en')" src="assets/icons/en.png" alt="Triple T">
            </div>

            <a class="online-services-btn" href="http://189.195.162.26:82" target="_blank" mat-flat-button color="primary">{{'common.onlineServs' | translate}}</a>

            <div class="social-mobile">
                <ul>
                    <li>
                        <a href="https://www.facebook.com/grupoceres.mx" target="_blank">
                            <img src="assets/icons/icon-fb.png" alt="Triple T Facebook">
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/grupo_ceres" target="_blank">
                            <img src="assets/icons/icon-tw.png" alt="Triple T Twitter">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/grupo_ceres/" target="_blank">
                            <img src="assets/icons/icon-ig.png" alt="Triple T Instagram">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/grupo-ceres/" target="_blank">
                            <img src="assets/icons/icon-in.png" alt="Triple T Linkedin">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>