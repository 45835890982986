import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MainService {

  public tabletBP!: boolean;
  public desktopBP!: boolean;
  public accion!: string | undefined;
  public setLanguage: Subject<string> = new Subject();

  constructor(private bpo: BreakpointObserver) {
    this.bpo.observe(['(min-width: 601px)', '(min-width: 900px)']).subscribe((bp: any) => {
      this.tabletBP = bp.breakpoints['(min-width: 601px)'];
      this.desktopBP = bp.breakpoints['(min-width: 900px)'];
    });
  }

  noWhitespaceValidator(control: FormControl): { [key: string]: any } | null {
    if (!control.value) return null;

    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'required': true };
  }

  onlyNumbersValidator(control: FormControl): { [key: string]: any } | null {
    if (!control.value) return null;

    let NUM_REGEXP: any = /^\d+$/;

    return NUM_REGEXP.test(control.value) ? null : { 'onlyNum': true };
  }

  setAccion(accion: string | undefined): void {
    this.accion = accion;
  }

  getAccion(): string | undefined {
    return this.accion;
  }
}
