import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        data: {
            pageTitle: 'pageTitle.home'
        }
    },
    {
        path: 'nosotros',
        loadChildren: () => import('./pages/nosotros/nosotros.module').then(m => m.NosotrosModule),
        data: {
            pageTitle: 'pageTitle.aboutus'
        }
    },
    {
        path: 'servicios',
        loadChildren: () => import('./pages/servicios/servicios.module').then(m => m.ServiciosModule),
        data: {
            pageTitle: 'pageTitle.services'
        }
    },
    {
        path: 'tarifas',
        loadChildren: () => import('./pages/tarifas/tarifas.module').then(m => m.TarifasModule),
        data: {
            pageTitle: 'pageTitle.prices'
        }
    },
    {
        path: 'contacto',
        loadChildren: () => import('./pages/contacto/contacto.module').then(m => m.ContactoModule),
        data: {
            pageTitle: 'pageTitle.contact'
        }
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
