import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  private onDestroy: Subject<void> = new Subject<void>();
  public lightNavbar: boolean = false;
  public isNavbarOpen: boolean = false;

  constructor(
    public mainServ: MainService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.initScrolObservable();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  initScrolObservable(): void {
    fromEvent(window, 'scroll')
      .pipe(
        takeUntil(this.onDestroy),
        map((event: any) => event.target.scrollingElement.scrollTop),
        // filter((scrollTop: number) => scrollTop <= 120)
      ).subscribe((scrollTop: number) => {
        this.lightNavbar = scrollTop >= 1;
      });
  }

  stopScrollObservable(): void {
    this.onDestroy.next();
  }

  openMobileMenu(): void {
    this.isNavbarOpen = true;
  }

  closeMobileMenu(): void {
    this.isNavbarOpen = false;
  }

  setLanguage(language: string): void {
    this.mainServ.setLanguage.next(language)
  }

}
