<ng-progress id="progress" color="#E53935" [spinner]="false" [thick]="false"></ng-progress>

<app-navbar></app-navbar>

<div class="outlet-container">
    <router-outlet></router-outlet>
</div>

<a class="float-wassa" href="https://bit.ly/3T6amjM" target="_blank">
    <img src="assets/icons/icon-wapp.png" alt="EXPOCERES">
</a>
